import React from 'react';

import { Page } from 'src/app/components';

const RGPD = () => {
  return (
    <Page>
      <Page.Main className="mx-auto max-w-screen-2xl">
        <div style={{ paddingTop: '82px' }}>
          <iframe
            title="RGPD"
            src="/equilibre-rgpd.pdf"
            className="w-full"
            style={{ minHeight: 'calc(100vh - 82px)' }}
          />
        </div>
      </Page.Main>
    </Page>
  );
};

export default RGPD;
